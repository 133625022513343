import React from 'react'
import useCmsInstitutional from 'src/hooks/useCmsInstitutional'
import { NpmInstitutionalPage } from '@plurix/ecom-pages'
import { DownloadIcon } from 'src/assets/DownloadIcon'
import pdf from 'src/assets/termos_e_condicoes_gerais_de_uso_compremais.pdf'
import '../../styles/pages/termos.scss'

const Terms = () => {
  const { termsBanners } = useCmsInstitutional()

  return (
    <NpmInstitutionalPage
      title="Termos e Condições Gerais de Uso"
      banners={termsBanners}
    >
      <div className="terms-container">
        <p>
          Apresentamos a você, usuário, os Termos e Condições de Uso, onde
          constam as principais regras a serem observadas por todos que acessam
          a plataforma digital do Compre Mais e suas funcionalidades.
        </p>
        <p>
          A plataforma digital{' '}
          <a
            href="https://clientecompremais.izio.com.br/portalcliente/"
            target="_blank"
            rel="noreferrer"
          >
            {'clientecompremais.izio.com.br/portalcliente'}
          </a>{' '}
          , disponibilizada e mantida{' '}
          <strong>SUPERPÃO SUPERMERCADO S/A – COMPRE MAIS</strong>., com sede no
          Estado do Paraná/PR, na Cidade de Guarapuava, na Rua Guaíra, nº 5555,
          Boqueirão, CEP: 85020-000, inscrito no CNPJ/MF sob o nº
          77883320/0001-61, (“Lojista”), disponibiliza serviço de Ecommerce
          convencional. Para melhor compreensão, vamos explicar o que significa:
        </p>
        <p>
          <strong>“E-commerce convencional”:</strong> venda e entrega realizada
          diretamente pelo Compre Mais, o qual dispõe de estoque próprio. Neste
          caso, abaixo do produto estará informado que o produto será vendido e
          entregue por{' '}
          <a
            href="https://clientecompremais.izio.com.br/portalcliente/"
            target="_blank"
            rel="noreferrer"
          >
            {'clientecompremais.izio.com.br/portalcliente'}
          </a>{' '}
          Ressaltamos que o E-commerce é destinado à venda de produtos e
          prestação de serviços diversos pela internet, sendo certo o nosso
          compromisso de buscar qualidade e segurança em todas as operações
          realizadas por meio da nossa plataforma.
        </p>

        <p>
          Como condição para acesso e utilização da plataforma{' '}
          <a
            href="https://clientecompremais.izio.com.br/portalcliente/"
            target="_blank"
            rel="noreferrer"
          >
            {'clientecompremais.izio.com.br/portalcliente'}
          </a>{' '}
          , esperamos que USUÁRIO faça a leitura completa e atenta das regras
          deste documento e das demais políticas (Política de Privacidade,
          Termos de uso e Cookies), estando plenamente ciente e de acordo com
          elas.
        </p>

        <p>
          Caso o USUÁRIO não concorde com referidas condições, poderá abster-se
          de consentir, por meio da não utilização da plataforma. Todavia, caso
          o USUÁRIO decida prosseguir com sua utilização, presumimos o seu
          consentimento e aceite às condições apresentadas neste documento.
        </p>

        <p>
          <strong>
            O conteúdo dos presentes Termos de Uso e Aceite deve ser analisado
            de maneira conjunta com o disposto em Aviso de Privacidade (Política
            Externa de Privacidade), disponível para acesso por meio do seguinte
            documento:
          </strong>
        </p>
        <p>
          <a
            href={pdf}
            download="termos_e_condicoes_gerais_de_uso_compremais.pdf"
            type="application/pdf"
          >
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Terms
